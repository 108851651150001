async function generateTable(cmsData) {
  const { tenants } = cmsData

  let tenantsObj = {}

  tenants.forEach(tenant => {
    const { _id, name = '' } = tenant
    const parsedName = name.toLowerCase().replace(' ','-')
    const clientUrl = 'https://jacksonsquare.101staging.com'

    // @TODO: handle special cases (e.g. elevators?) here

    let tObj = JSON.parse(JSON.stringify(tenant))
    tObj.link = `${clientUrl}/tenant/${parsedName}/${_id}/`
    tenantsObj[_id] = tObj
  })

  return tenantsObj
}

export default generateTable